@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

*{
  padding: 0;
  margin: 0;
}

body {
  box-sizing:  border-box;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFDFF;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-div-icon {
  position: absolute;
  background: none;
  border: none;
  display: flex;
  z-index: 1500;
  height: 52px;
  width: 36px;
}

.div-icon {
  width: 20px;
  height: 20px;
  background: none;
  border: none;
}

.div-icon img {
  width: 32px;
  height: 32px;
}

.circle {
  position: relative;
  background: white;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  top: -53.8px;
  left: 15.8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  color: #000000;
}


.active {
  padding: 0px;
  top: -38.5px;
  left: -6px;
  display: inline-block;
  position: relative;
  z-index: 0;
}
.active::before {
  width: 29px;
  aspect-ratio: 1;
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 8px;
  border-radius: 100%;
  background: linear-gradient(to right,
  rgba(175, 142, 245, 1),
  rgba(228, 119, 241, 1),
  rgba(241, 145, 84, 1),
  rgba(245, 193, 25, 1));
  mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.custom-tooltip img {
  width: 280px;
  height: 180px;
  object-fit: cover;
  }

.custom-tooltip{
  border-radius: 0;
  height: 350px;
  width: 280px;
  background: white;
  overflow-y: scroll;
}

.custom-tooltip h1{
  margin: 0 auto;
  padding: 10px;
  color: #3E1DAA;
  text-align: center;
  /* Desktop/Cabinet Grotesk/Heading-6 */
  font-family: Cabinet Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  max-width: 100%;
}

.custom-tooltip p{
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 12px;
  font-family: "Lato";
  color:#1F0812;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin: 0 auto;
}

.custom-tooltip button {
  color: black;
  background: none;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border: none;
  border-bottom: 2px solid #3E1DAA;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 20px auto;
}

.custom-tooltip button:hover {
  border: none;
  transition: border 0.3s
}


.custom-tooltip div{
  display: flex;
  flex-direction: column;
position: relative;
  /*justify-content: center;*/
  /*align-items: center;*/
  border-radius: 0;
  margin: 0;
  height: 350px;
  width: 280px;
  border-bottom: #fff75f 8px solid;
}

.custom-tooltip .leaflet-popup-tip-container{
  height: 20px;
  width: 30px;
  position: relative;
  margin: 0;
left: 120px;
  border: none;
}

.custom-tooltip .leaflet-popup-tip{
  background: #fff75f;
  position: relative;
  margin: -26px 0;
  height: 20px;
width: 30px;
  box-shadow: none;
}

.leaflet-popup-close-button{
  display: none;
}
#map{
  width: 94vw;
  height: 110vh;
  display: flex;
  margin: 0 auto;
  border-radius: 20px;
}

